/*
 * @Author: lxiang
 * @Date: 2022-05-16 09:50:42
 * @LastEditors: lxiang
 * @LastEditTime: 2023-03-15 21:11:05
 * @description: Modify here please
 * @FilePath: \sea_mobile\src\router\login.js
 */
export const login = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/Login"),
  },
];
