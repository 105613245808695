/*
 * @Author: lxiang
 * @Date: 2022-05-30 17:45:34
 * @LastEditors: lxiang
 * @LastEditTime: 2022-05-31 10:23:38
 * @description: Modify here please
 * @FilePath: \sea_mobile\src\i18n\lang\cn.js
 */
const cn = {
  home: "主页",
  language: "语言设置",
  skin: "主题",
  login: "登录",
  close: "注销账户",
  logout: "退出登录",
};
export default cn;
